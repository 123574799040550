import React, { useEffect, useState } from "react";

import PageLayout from "../../components/page-layout";

import ukraineImage from "../../images/ukraine.png";
import { formatDateTime } from "../../utils/date";

import * as style from "../call-status.module.css";

const fetchConsultant = async (consultantId) => {
  const response = await fetch(`/api/v1/calls/${callId}`);
  const result = await response.json();
  if (!result.ok)
    throw new Error(`Failed to fetch call "${callId}": ${result.error}`);
  return result.call;
};

const fetchCall = async (callId) => {
  const response = await fetch(`/api/v1/calls/${callId}`);
  const result = await response.json();
  if (!result.ok)
    throw new Error(`Failed to fetch call "${callId}": ${result.error}`);
  return result.call;
};

const STATUSES = {
  requested: "Requested",
  rejected: <span className={style.rejected}>Rejected by mentor</span>,
  confirmed: <span className={style.confirmed}>Confirmed by mentor</span>,
};

const CallStatusPage = ({ location }) => {
  const [call, setCall] = useState();

  useEffect(() => {
    const { searchParams } = new URL(location.href);
    const token = searchParams.get("t");
    const callId = searchParams.get("u");

    fetchCall(callId)
      .then(setCall)
      .catch((e) => {
        throw e;
      });
  }, []);

  return (
    <PageLayout
      title="Call A Colleague"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={ukraineImage}
    >
      <section id="above-fold" className={style.aboveFold}>
        <img src={ukraineImage} width="64" height="42" />

        {call?.error && (
          <div className={style.errorMessage}>
            Something went wrong: <b className={style.rejected}>{call.error}</b>
          </div>
        )}

        <h1>Your Call</h1>

        <table className={style.table}>
          <tbody>
            <tr>
              <td>Mentor</td>
              <td>{call?.consultant?.name}</td>
            </tr>
            <tr>
              <td>Mentee</td>
              <td>{call?.name}</td>
            </tr>
            <tr>
              <td>Date / Time</td>
              <td>{formatDateTime(call?.datetime)} (Berlin time)</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{STATUSES[call?.status]}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </PageLayout>
  );
};

export default CallStatusPage;
